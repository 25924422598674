<script setup lang="ts">
import { OrganismReviewsProps } from "./OrganismReviews.props"

const props = defineProps<OrganismReviewsProps>()

const reviewsListElement = computed(() => {
  const reviews = props?.productReviews.map((review) => {
    const [first, last] = review?.reviewerName?.split(" ")
    const truncateSurname = last?.charAt(0) ?? ""
    return cleanJsonLdObject({
      "@type": "Review",
      ...(first || truncateSurname
        ? {
            author: {
              "@type": "Person",
              givenName: first ?? "",
              familyName: truncateSurname ?? ""
            }
          }
        : {}),
      datePublished: review?.date ?? "",
      reviewBody: review?.textReview ?? "",
      reviewRating: {
        "@type": "Rating",
        bestRating: "5",
        ratingValue: review?.rating ?? 1,
        worstRating: "1"
      }
    })
  })
  return reviews
})

const emit =
  defineEmits<{
    (e: "onSearch", value: string): void
  }>()

useHead({
  script: [
    {
      type: "application/ld+json",
      children: JSON.stringify(
        cleanJsonLdObject({
          "@context": "https://schema.org",
          "@type": props.isProductDrug ? "Drug" : "Product",
          ...((props.reviewNumber ?? 0) > 0
            ? {
                aggregateRating: {
                  "@type": "AggregateRating",
                  ratingValue: props.reviewAverage,
                  reviewCount: props.reviewNumber
                }
              }
            : {}),
          name: props.productName,
          review: reviewsListElement.value
        })
      )
    }
  ]
})

const allReviewsOpen = ref<boolean>(false)

const reviewsList = computed(() => {
  if (allReviewsOpen.value) return props.productReviews
  return [...props.productReviews].slice(0, 5)
})
</script>

<template>
  <div class="organism-reviews-wrapper flex flex-col gap-6 md:flex-row">
    <div>
      <div class="flex items-center gap-4 md:flex-col md:items-start md:gap-0">
        <div class="flex justify-center md:w-full">
          <UtilsIcon
            name="Feedaty2.svg"
            class="h-16 w-16 md:mb-6 md:h-40 md:w-40"
          />
        </div>

        <div v-if="reviewNumber" class="flex flex-col">
          <h2 class="pig-bold md:elephant-bold md:mb-3">
            {{ $t("productPage.organismReviews.clientReview") }}
          </h2>
          <span class="beaver-medium md:pig-medium text-black-80">
            {{ reviewNumber }}
            {{ $t("productPage.organismReviews.totalReview") }}
          </span>
        </div>
      </div>

      <p v-if="productName" class="beaver-bold dolphin-bold mb-6 mt-4">
        {{ productName }}
      </p>

      <div
        class="
          flex
          min-w-max
          flex-col
          gap-3
          bg-grey-main
          pb-4
          pl-3
          pr-4
          pt-3
          md:pb-10
        "
      >
        <p v-if="reviewAverage" class="pig-medium mb-2">
          <span class="whale-bold">
            {{ reviewAverage }}
          </span>
          {{ $t("productPage.organismReviews.onFiveStar") }}
        </p>

        <div
          v-if="reviewsByStars"
          v-for="({ reviewNumber }, index) in reviewsByStars"
          :key="`star${index}`"
          class="flex items-center justify-between gap-2"
        >
          <UtilsStars :size="5" :stars="5 - index" />
          <p class="beaver-medium whitespace-nowrap text-black-80">
            {{ reviewNumber }}
            {{ $t("productPage.organismReviews.reviews") }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex w-full flex-col gap-6">
      <div
        class="
          flex flex-col
          gap-6
          md:max-w-[65%]
          md:flex-row
          md:items-center
          md:justify-between
          md:gap-7
        "
      >
        <SearchSearchbar
          v-if="searchSettings"
          v-bind="searchSettings"
          @update:modelValue="(newValue) => emit('onSearch', newValue)"
          :placeholder="$t('productPage.organismReviews.findKeyword')"
        />

        <!-- ! In the future advanced filtering may be supported -->
        <!--
        <div class="flex flex-shrink-0 items-center gap-4">
          <span class="beaver-medium ml-2">
            {{ $t("productPage.organismReviews.orderBy") }}
          </span>

          <TextfieldsDropdown
            v-if="dropdownMenu"
            :defaultSelected="dropdownMenu[0].label"
            :dropdownMenu="dropdownMenu"
          />
        </div>
        -->
      </div>

      <ProductPageReviewsMoleculeReview
        v-if="reviewsList"
        v-for="review in reviewsList"
        v-bind="review"
      />

      <div
        v-if="reviewsList.length >= 5 && !allReviewsOpen"
        class="w-full md:grid md:grid-cols-8"
      >
        <UtilsButton
          @click="allReviewsOpen = true"
          class="w-full md:col-start-5 md:col-end-9"
          theme="outlined-green"
          :text="
            $t('productPage.organismReviews.loadOtherReview') +
            `(${reviewNumber - 5})`
          "
        />
      </div>
    </div>
  </div>
</template>
